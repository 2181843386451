import jQuery from 'jquery';

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;( function( $, window, document ) {

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variables rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "paddack",
			defaults = {
                save: function() {},
                initialized: function(elt, c) {c()},
                autosaveTimeout: 10,
				wordLimit: null,
                charLimit: null
			};

		// The actual plugin constructor
		function Plugin ( element, options ) {
			this.element = element;

			// jQuery has an extend method which merges the contents of two or
			// more objects, storing the result in the first object. The first object
			// is generally empty as we don't want to alter the default options for
			// future instances of the plugin
			this.settings = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;
			this.init();
		}

        var cleanVal = "";
        var blurVal = "";
        var tmAutosave = null;
		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
			init: function() {

				// Place initialization logic here
				// You already have access to the DOM element and
				// the options via the instance, e.g. this.element
				// and this.settings
				// you can add more functions like the one below and
				// call them like the example below
                var settings = this.settings;
                var elt = this.element;
                var jqElt = $(elt);
                var jqContainer = jqElt.parent();
                
                function trim(){return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '');};
                function alterColour(rgb, type, percent) {
                    rgb = rgb.replace('rgb(', '').replace(')', '').split(',');

                    var red = trim.call(rgb[0]);
                    var green = trim.call(rgb[1]);
                    var blue = trim.call(rgb[2]);

                    if(type === "darken") {
                    red = parseInt(red * (100 - percent) / 100, 10);
                    //green = parseInt(green * (100 - percent) / 100, 10);
                    //blue = parseInt(blue * (100 - percent) / 100, 10);
                    } else {
                    red = parseInt(red * (percent) / 100, 10);
                    //green = parseInt(green * (100 + percent) / 100, 10);
                    //blue = parseInt(blue * (100 + percent) / 100, 10);
                    }

                    rgb = 'rgb(' + red + ', ' + green + ', ' + blue + ')';

                    return rgb;
                }
                
                function colourMeBad(jqElt, bad) {
                    jqElt.css("color", alterColour("rgb(255,0,0)", "l", 100*bad));
                }
                
                function imposeWordLimit() {
                    if( !settings.wordLimit )
                        return;
                        
                    var words = 0;
                    var wordmatch = elt.value.match(/[^\s]+\s+/g);
                    
                    words = wordmatch?wordmatch.length:0;

                    if (words > settings.wordLimit) {
                        var trimmed = jqElt.val().split(/(?=[^\s]\s+)/, settings.wordLimit).join("");
                        var lastChar = jqElt.val()[trimmed.length];
                        jqElt.val(trimmed + lastChar);
                    }
                    $('.word-count', jqContainer).text(words);
                    $('.words-left', jqContainer).text(Math.max(settings.wordLimit-words, 0));
                    colourMeBad($('.words-left-container', jqContainer), (words)/settings.wordLimit);
                }
                
                function imposeCharLimit() {
                    if( !settings.charLimit )
                        return false;
                        
                    var chars = get_val().length;

					$('.char-count', jqContainer).text(chars);
					$('.chars-left', jqContainer).text(settings.charLimit-chars > 0 ? settings.charLimit-chars : 0);
                    colourMeBad($('.chars-left-container', jqContainer), (chars)/settings.charLimit);


                    if(settings["ck-editor"])
                    {
	                    if (chars > settings.charLimit) {
	                    	$(".document-editor__editable-container")[0].style.backgroundColor = '#FFC4C4';
	                        return true;
	                    }
	                    else {
	                    	$(".document-editor__editable-container")[0].removeAttribute("style");
	                   	}
	                }
	                else {
                        set_val(get_val().substring(0, settings.charLimit));
	                }
                }
                
                function get_text() {
                	return get_val().text
                }

                function get_val()
                {
                    if(settings["ck-editor"])
                    {
                        var editor = settings["ck-editor"]
                        return editor.getData();
                    }
                    else
                    {
                        return jqElt.val();
                    }
                }
                
                function set_val(v)
                {
                    if(settings["ck-editor"])
                    {
                        var editor = settings["ck-editor"]
                        return editor.setData(v);
                    }
                    else
                    {
                        return jqElt.val(v);
                    }
                }
                
                if( jqElt.attr("data-word-limit") )
                {
                    settings.wordLimit = parseInt(jqElt.attr("data-word-limit"));
                }
                if( settings.charLimit === null )
                {
	                if( jqElt.attr("data-char-limit") )
	                {
	                    settings.charLimit = parseInt(jqElt.attr("data-char-limit"));
	                }
	                else
	                {
	                    settings.charLimit = 10000;
	                }
	            }
                
                var initCallback = function() {
                    imposeWordLimit();
                    imposeCharLimit();
                    var currentVal = get_val();
                    cleanVal = currentVal;
                    blurVal = currentVal;
                    
                };
                this.settings["initialized"](elt, initCallback);
                
                function save()
                {
                    console.log("Dirty true");
                    var currentVal = get_val();
                    cleanVal = currentVal;
                    blurVal = currentVal;
                    
                    settings["save"](elt, function() {
                    });
                }
                
                var change_function = function() {
                    if( settings.wordLimit )
                    {
                    	imposeWordLimit();
                    }
                    
                    if( settings.charLimit )
                    {
                    	if( imposeCharLimit() ){
                    		return false;
                    	};
                    }

                    var currentVal = get_val();
                    if(currentVal === cleanVal) {
                        return; //check to prevent multiple simultaneous triggers
                    }
                    clearTimeout(tmAutosave);
                    tmAutosave = setTimeout(function() { console.log("Autosave..."); save(); }, 1000*settings.autosaveTimeout);
                }
                    
                if(settings["ck-editor"])
                {
                    // Look for CK editor event change
                    var editor = settings["ck-editor"]
                    editor.ui.focusTracker.on( 'change:isFocused', ( evt, name, value ) => {
                        if(!value)
                        {
                            var currentVal = editor.getData();
                            if(currentVal === blurVal) {
                                return;
                            }
                            clearTimeout(tmAutosave);
                            save();
                        }
                        else
                        {
                            if(settings["focus"])
                                settings["focus"](elt, evt);
                        }
                    });
                    
                    editor.model.document.on( 'change:data', () => {
                        return change_function();
                    });
                }
                else
                {
                    jqElt.on("focus", function(e) {
                        if(settings["focus"])
                            settings["focus"](elt, e);
                    });
                    
                    jqElt.on("blur", function(e) {
                        var currentVal = get_val();
                        if(currentVal === blurVal) {
                            return;
                        }
                        clearTimeout(tmAutosave);
                        save();
                    });
                    
                    jqElt.on("change keyup paste", change_function);
                }
			}
		} );

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
			return this.each( function() {
				if ( !$.data( this, "plugin_" + pluginName ) ) {
					$.data( this, "plugin_" +
						pluginName, new Plugin( this, options ) );
				}
			} );
		};

} )( jQuery, window, document );
