import React, { useEffect, useRef } from 'react';
import { IonItem, useIonViewWillEnter, IonSelect, IonSelectOption, useIonViewDidLeave } from '@ionic/react';
import {queryPortsLikeName} from '../services/query'
import { Country, Port } from '../services/models'

interface ContainerProps {
	onSearchChange: CallableFunction;
	country: Country|null
}

const PortSelect: React.FC<ContainerProps> = ({onSearchChange, country }) => {
	let [ports, setPorts] = React.useState<Port[]>([]);
	const portSelect = useRef<HTMLIonSelectElement>(null);

	const handleChange = async (e:any) => {
		await onSearchChange(e.detail.value);
	}

    const getPorts = async (query:string) => {
        let p = await queryPortsLikeName(query);
		setPorts(p);
    }

	useEffect(() => {
		async function getCountries(country: Country) {
			let p = null;
			if(country && country.id) {
				p = await queryPortsLikeName("", country.id.toString());
			}
			else {
				p = await queryPortsLikeName("");
			}

			setPorts(p);
		}
		if( country != null )
		{
			getCountries(country);
		}
		if(portSelect && portSelect.current) {
			portSelect.current.value = null;
		}
	}, [country])

	useIonViewWillEnter(async () => {
		await getPorts("");
	});

	useIonViewDidLeave(async () => {
		if( portSelect && portSelect.current)
		{
			portSelect.current.value = null;
		}
	});

	return (
		<IonItem key="selectPort">
			<IonSelect 
				ref={portSelect}
			 	interface="action-sheet"
				onIonChange={handleChange}
				placeholder="Select port:"
			>
				{
					ports.map((p) =>
						<IonSelectOption value={p.id} key={p.id}>
							{
								p.port_name == "All Ports" ? p.port_name + ", " + (p.country ? p.country.country_name : "") : p.port_name 
							}
						</IonSelectOption>
					)
				}
			</IonSelect>
		</IonItem>
	);
};

export default PortSelect;
