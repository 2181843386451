import React, { useContext } from 'react';
import { UKPICorrespondent } from '../services/models';
import { phonePortrait, call, mail, globe, shareOutline } from 'ionicons/icons';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonList, useIonAlert } from '@ionic/react';
import { toggleFavouriteCorrespondent, contactToCordovaContact, queryContact } from '../services/query';
import { star } from 'ionicons/icons';
import { starOutline } from 'ionicons/icons';
import { ToastContext } from '../services/contexts';
import { Capacitor } from '@capacitor/core';

interface ContainerProps {
	correspondent: UKPICorrespondent;
}

const CorrespondentDetail: React.FC<ContainerProps> = ({ correspondent }) => {
	const { message, setMessage } = useContext(ToastContext);
	const [present] = useIonAlert();

	const toggleThisFavouriteCorrespondent = async (e: any) => {
		let elt = e.currentTarget;
		let correspondentId = elt.getAttribute("data-correspondent");
		if (correspondentId) {
			let isFav = await toggleFavouriteCorrespondent(correspondentId);
			if (isFav) {
				setMessage("Favourite added");
			}
			else {
				setMessage("Favourite removed");
			}
			elt.icon = isFav ? star : starOutline;
		}
	}

	const shareContact = async (e: any) => {
		let elt = e.currentTarget;
		let contactId = elt.getAttribute("data-contact");
		let contactName = elt.getAttribute("data-name");
		let existingContact = await queryContact(contactName);
		if (contactId) {
			if (existingContact && Capacitor.getPlatform() == "ios") {
				present({
					header: 'Replace contact?',
					message: 'You already have a contact called ' + contactName + ':',
					buttons: [
						'Cancel',
						{
							text: 'Create new', handler: async (d) => {
								await contactToCordovaContact(contactId, null);
								setMessage("Contact added");
							}
						},
						{
							text: 'Replace', handler: async (d) => {
								await contactToCordovaContact(contactId, existingContact);
								setMessage("Contact updated");
							}
						}
					]
				});
			}
			else {
				present({
					header: 'Confirm',
					message: 'Are you sure you want to add ' + contactName + ' as a contact?',
					buttons: [
						'Cancel',
						{
							text: 'Ok', handler: async (d) => {
								await contactToCordovaContact(contactId, null);
								setMessage("Contact added");
							}
						}
					]
				});
			}
		}
	}

	/*
	const toggleThisFavouriteContact = async (e: any) => {
		let elt = e.currentTarget;
		let contactId = elt.getAttribute("data-contact");
		if(contactId) {
			let isFav = await toggleFavouriteContact(contactId);
			if( isFav )
			{
				setMessage("Favourite added");
			}
			else {
				setMessage("Favourite removed");
			}
			elt.icon = isFav ? star : starOutline;
		}
	}
	*/

	return (
		<IonCard className="correspondent-detail w-100">
			<IonCardHeader>
				<IonCardSubtitle>{correspondent.port.port_name}, {correspondent.country.country_name}</IonCardSubtitle>
				<IonCardTitle>
					{correspondent.correspondent_name}

					{!correspondent.refer_to &&
						<>
							<IonIcon className="ion-float-end" icon={correspondent.is_favourite ? star : starOutline} data-correspondent={correspondent.correspondent_id} onClick={toggleThisFavouriteCorrespondent}></IonIcon>
						</>
					}
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				{correspondent.refer_to ?
					<IonItem className="refer-to">
						REFER TO: {correspondent.refer_to}
					</IonItem>
					:
					<div>
						<address>
							{correspondent.address_lines.map((l) =>
								<span key={l.id}>{l.line}<br /></span>
							)}
						</address>
						<IonList className="phones">
							{correspondent.phones.map((op) =>
								<IonItem key={op.id}>
									<a href={"tel:" + op.phone_number}>
										<IonIcon icon={call} />
										{op.phone_number}
									</a>
									{op.additional_text &&
										<span className="additional-text"> {op.additional_text}</span>
									}
								</IonItem>
							)}
						</IonList>
						<IonList className="phone_contacts">
							{correspondent.correspondent_email &&
								<IonItem >
									<a href={"mailto:" + correspondent.correspondent_email}>
										<IonIcon icon={mail} />
										{correspondent.correspondent_email}
									</a>
								</IonItem>
							}
							{correspondent.website &&
								<IonItem>
									<a target="_correspondentwin" href={correspondent.website}><IonIcon icon={globe} /> {correspondent.website}</a>
								</IonItem>
							}
						</IonList>
						{correspondent.contacts.map((contact) =>
							<div className="contact" key={contact.id}>
								<h4>
									{contact.contact_name}
									&nbsp;
									<IonIcon className="ion-float-end" icon={shareOutline} data-contact={contact.contact_id} onClick={shareContact} data-name={contact.contact_name}></IonIcon>
								</h4>

								<IonList className="correspondent_phones">
									{contact.contact_email &&
										<IonItem>
											<a href={"mailto:" + contact.contact_email}>
												<IonIcon icon={mail} />
												{contact.contact_email}
											</a>
										</IonItem>
									}
									{contact.phones.map((cp) =>
										<IonItem key={cp.id}>
											{cp.is_mobile ?
												<a href={"tel:" + cp.phone_number}><IonIcon icon={phonePortrait} />
													{cp.phone_number}</a>
												:
												<a href={"tel:" + cp.phone_number}><IonIcon icon={call} />
													{cp.phone_number}</a>
											}
											{cp.additional_text &&
												<span> {cp.additional_text}</span>
											}
										</IonItem>
									)}
								</IonList>
							</div>
						)}
					</div>
				}
			</IonCardContent>
		</IonCard>
	)
}

export default CorrespondentDetail;
