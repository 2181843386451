import React, { useContext, useState } from 'react';
import { IonContent, IonPage, IonButton, useIonViewWillEnter } from '@ionic/react';
import { queryLastUpdated, wipeAndRebuildDbs } from '../services/query';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { ToastContext, UKPIContext } from '../services/contexts';
import { dateCheckLocal, dateCheckRemote } from '../services/api';

const TabManage: React.FC = () => {
    const [lastUpdated, setLastUpdated] = useState("");
    const { loading, setLoading } = useContext(UKPIContext);
    const { message, setMessage } = useContext(ToastContext);

    useIonViewWillEnter(async () => {
        console.log("Entering management");
        let last_updated = await queryLastUpdated();
        setLastUpdated(last_updated);
    });
    
    async function doCheckJson() {
        setMessage("Checking for new data, please wait...");
        setLoading("loading");
        if (navigator.onLine) {
            let result = await dateCheckRemote();
            setMessage(result.message);
            if( result.success ) {
                let last_updated = await queryLastUpdated();
                setLastUpdated(last_updated);
                setLoading("ready");
            }
        }
        else {
            setMessage("Device is offline");
        }
    }
  
    async function doWipe() {
      setMessage("Starting wipe and reload, please wait...");
      console.log("Starting wipe and reload");
      setLoading("loading");
      await wipeAndRebuildDbs();
      if (navigator.onLine) {
          console.log("Online. check remote date");
          let result = await dateCheckRemote();
          setMessage(result.message);
          if( result.success ) {
            setLoading("ready");
          }
          else {
            setMessage("Loading local data");
            console.log("Error. check local date");
            result = await dateCheckLocal();
            setMessage(result.message);
            if( result.success ) {
                setLoading("ready-local");
            }
            else {
                setLoading("failed");
            }
          }
      }
      else {
        console.log("Offline. check local date");
        let result = await dateCheckLocal();
        setMessage(result.message);
        if( result.success ) {
            setLoading("ready-local");
        }
      }
      let last_updated = await queryLastUpdated();
      setLastUpdated(last_updated);
    }

    return (
        <IonPage >
            <UKPIHeader />
			<SearchProxy />
            <IonContent fullscreen className="ion-padding">
                <div className="mh-100">
                    <div className="flex-row">
                        <h1>Manage Data</h1>
                        <p>
                            Correspondent data last updated:<br />
                            <strong>{lastUpdated}</strong>
                        </p>
                        <p>
                            This app checks for updated correspondent data every time it is loaded. 
                            Use this button to perform that check manually:
                        </p>
                        <IonButton expand="block" onClick={doCheckJson}>
                            Check for new data now
                        </IonButton>
                        <p>
                            Use this button to delete and reload the local database:
                        </p>
                        <IonButton expand="block" onClick={doWipe}>
                            Wipe and reload data
                        </IonButton>
                        <p>
                            The bar below the header will be coloured <span className="loading">&nbsp;grey&nbsp;</span> while the checks take place.
                        </p>
                        <p>
                            This app maintains an copy of the data for offline use. 
                            The bar above will go <span className="ready-local">&nbsp;yellow&nbsp;</span> when that's loaded.
                        </p>
                        <p>
                            If you are online, we'll check for more up-to-date data on our servers and load that. 
                            When that's done, the bar above will go <span className="ready">&nbsp;green&nbsp;</span>.
                        </p>
                    </div>
                </div>
                <UKPIFooter />
            </IonContent>
        </IonPage>
    );
};

export default TabManage;

