/* eslint-disable no-multi-str */
import { handleError, u2n, toPath, httpGet, fileGet, asyncForEach } from "./utils";
import { asyncTransaction, doQuery, emptyDbs, queryFavouriteTable } from "./query";
import { Capacitor } from "@capacitor/core";
import * as Interfaces from './interfaces'


let crispUpdateUrl = "https://connect.thomasmiller.com/UKPI_Api/rest/api/CorrespondentFinderLastModifiedDate/4b078b0ab1664843a37cce397758eb3f/v1/";
// Test url
// crispUpdateUrl = "https://www.coracle-demo.com/test/ludt.json";
let crispUrl = "https://connect.thomasmiller.com/UKPI_Api/rest/api/CorrespondentFinder/4b078b0ab1664843a37cce397758eb3f/v1/?NumberRows=1000000";

export async function fetchCorrespondentsLastUpdated(): Promise<Interfaces.CorrespondentDatabaseLastUpdatedJson> {
    console.log("Fetching correspondent last update from API");
    let response = await httpGet(crispUpdateUrl);
    let data2 = response as Interfaces.CorrespondentDatabaseLastUpdatedJson;
    return data2;
}


export async function fetchCorrespondents(): Promise<Interfaces.CorrespondentDatabaseJson[]> {
    console.log("Fetching correspondents from API");
    let response = await httpGet(crispUrl);
    let data2 = response as Interfaces.CorrespondentDatabaseJson[];
    return data2;
}


let shipUrl = "https://connect.thomasmiller.com/UKPI_Api/rest/api/ShipFinder/4b078b0ab1664843a37cce397758eb3f/v1/";
export async function fetchShips(searchText: string): Promise<Interfaces.Ship[]> {
    console.log("Fetching ships from API");
    let response = await httpGet(shipUrl + encodeURI("?Search=" + searchText + "&NumberRows=5"));
    let data2 = response as Interfaces.Ship[];
    console.log("Fetched ships from API");
    return data2;
}

/*
At the moment, this work in done in App.tsx and TabManage, so it can report

export async function dateCheck() {
    console.log("Check local date");
    await dateCheckLocal();
    if (false && navigator.onLine) {
        console.log("Check remote date");
        await dateCheckRemote();
    }
}
*/

export async function dateCheckRemote() : Promise<Interfaces.ActionResult> {
    console.log("Checking remote date updated");
    try {
        let data = await fetchCorrespondentsLastUpdated();
        return await compareDates(data, true);
    }
    catch (e) {
        // offline 
        return new Interfaces.ActionResult(false, "Device is offline");
    }
}

export async function dateCheckLocal() : Promise<Interfaces.ActionResult> {
    let fileUrl = toPath("initial-data/CorrespondentDatabaseLastUpdatedRetrieve.json");
    try {
        if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
            // make a FileReader to passed in fileGet()
            // can't define in fileGet because seems near impossible to 
            // return the data back as it is using call backs

            let beef = await fileGet('public/initial-data', 'CorrespondentDatabaseLastUpdatedRetrieve.json', 'utf-8');
            let data = JSON.parse(beef);
            return await compareDates(data, false);
        }
        else {
            let data = await httpGet(fileUrl);
            return await compareDates(data, false);
        }
    }
    catch (e) {
        handleError(e, "Could not check local JSON: " + e.message);
        return new Interfaces.ActionResult(false, "Error checking date offline");
    }
}


export async function compareDates(dateJson: any, isRemote: boolean) : Promise<Interfaces.ActionResult> {
    let sDate = dateJson["LastUpdateDateTime"];
    let dDate = new Date(sDate)
    let tx = await asyncTransaction();
    let rs = await doQuery(tx, "SELECT last_updated_date FROM last_updated ORDER BY DATETIME(last_updated_date) DESC LIMIT 1;", []) as any;
    try {
        if (rs && rs.rows.length > 0) {
            let sLastUpdated = rs.rows.item(0).last_updated_date;
            let dLastUpdated = new Date(Date.parse(sLastUpdated));
            console.info("Comparing date: " + dDate + " to SQL date: " + dLastUpdated);
            if (dLastUpdated.getTime() < dDate.getTime()) {
                console.log("Not identical");
                await insertDateData(dateJson);
                await fetchOrLoadCorrespondents(isRemote);
                if( isRemote )
                    return new Interfaces.ActionResult(true, "Updated correspondents from UKP&I");
                else 
                    return new Interfaces.ActionResult(true, "Updated correspondents from device");
            }
            else {
                console.log("No updates needed");
                return new Interfaces.ActionResult(true, "No updates needed");
            }
        }
        else {
            console.warn("No Last updated data found, reloading");
            await insertDateData(dateJson);
            await fetchOrLoadCorrespondents(isRemote);
            if( isRemote )
                return new Interfaces.ActionResult(true, "Loaded correspondents from UKP&I");
            else 
                return new Interfaces.ActionResult(true, "Loaded correspondents from device");
        }
    }
    catch (e) {
        handleError(e, e.message);
        return new Interfaces.ActionResult(false, "Error comparing dates");
    }
}


export async function fetchOrLoadCorrespondents(isRemote: boolean) {
    if (isRemote) {
        await fetchAndInsertCorrespondents();
    }
    else {
        await loadAndInsertCorrespondents();
    }
}


export async function loadAndInsertCorrespondents() {
    console.log("Loading correspondents from app");
    let fileUrl = toPath("initial-data/CorrespondentDatabaseRetrieve.json");

    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
        let beef = await fileGet('public/initial-data', 'CorrespondentDatabaseRetrieve.json', 'utf-8');

        let data = JSON.parse(beef);
        let data2 = data as Interfaces.CorrespondentDatabaseJson[];
        await insertCorrespondentData(data2);
    }
    else {
        let response = await httpGet(fileUrl);
        let data2 = response as Interfaces.CorrespondentDatabaseJson[];
        await insertCorrespondentData(data2);
    }
}


export async function fetchAndInsertCorrespondents() {
    try {
        let data = await fetchCorrespondents();
        await insertCorrespondentData(data);
    }
    catch (e) {
        handleError(e, e.message);
    }
}


export async function insertDateData(dateJson: Interfaces.CorrespondentDatabaseLastUpdatedJson) {
    let sDate = dateJson["LastUpdateDateTime"];
    console.info("Storing date: " + sDate);
    let tx = await asyncTransaction();
    const values: string[] = [new Date(sDate).toISOString()];
    await doQuery(tx, "INSERT INTO last_updated (last_updated_date) VALUES (?)",
        values as any
    );
}


export async function insertCorrespondentData(data: Interfaces.CorrespondentDatabaseJson[]) {
    var tx = await asyncTransaction();
    await emptyDbs(tx);
    let [favouriteCrisps, favouriteContacts] = await queryFavouriteTable(tx);
    console.info("Beginning refill...");
    await asyncForEach(
        data,
        async function (oCountry: Interfaces.CorrespondentDatabaseJson) {
            let countryName = u2n(oCountry["CountryDescription"]);
            let countryId = oCountry["CorrespondentCountryID"];
            const values: string[] = [countryId, countryName];

            let result = await doQuery(tx, "INSERT INTO country (id, country_name) VALUES (?, ?)",
                values as any
            ) as any;

            if (!oCountry["CorrespondentPortList"]) {
                return;
            }

            let myCountryId = result["insertId"];

            // Because I need to store the port id here, I need to make sure this happens in sequence
            await asyncForEach(
                oCountry["CorrespondentPortList"],
                async function (oPortEssed: Interfaces.CorrespondentPortListEntity) {
                    let oPort = oPortEssed["s_CorrespondentPort"];
                    let portName = u2n(oPort["PortDescription"]);
                    let portId = oPort["CorrespondentPortID"];
                    
                    let rs = await doQuery(tx, "SELECT id FROM port WHERE port_id=?",
                        [portId] as any
                    ) as any;

                    let myPortId = 0;
                    if (rs && rs.rows.length > 0) {
                        myPortId = rs.rows.item(0).id;
                    }
                    else {
                        const values: string[] = [portId, portName, countryId];
                        result = await doQuery(tx, "INSERT INTO port (port_id, port_name, country_id) VALUES (?, ?, ?)",
                            values as any
                        );
                        myPortId = result["insertId"];
                    }
                    if (!oPort["CorrespondentList"]) {
                        return;
                    }
                    await asyncForEach(
                        oPort["CorrespondentList"],
                        async function (oEssedCorrespondent: Interfaces.CorrespondentListEntity) {
                            let oCorrespondent = oEssedCorrespondent["s_Correspondent"];
                            let correspondentId = parseInt(u2n(oCorrespondent["CorrespondentID"]));
                            let correspondentName = u2n(oCorrespondent["CorrespondentName"]);
                            let correspondentEmail = u2n(oCorrespondent["EmailAddress"]);
                            let correspondentWebsite = u2n(oCorrespondent["WebsiteAddress"]);
                            let correspondentReferTo = u2n(oCorrespondent["ReferTo"]);
                            let correspondentSort = parseInt(u2n(oCorrespondent["Sort"]));
                            let is_general = oCorrespondent["IsGeneralCorrespondent"] ? 1 : 0;
                            let is_favourite = favouriteCrisps.includes(correspondentId) ? 1 : 0;
                            if (correspondentWebsite && correspondentWebsite.length < 8) {
                                //"http://"
                                correspondentWebsite = "";
                            }

                            const values: string[] = [
                                correspondentId,
                                correspondentName,
                                correspondentEmail, correspondentSort,
                                is_general, is_favourite, correspondentWebsite, correspondentReferTo,
                                myPortId, myCountryId];

                            result = await doQuery(tx, "INSERT INTO correspondent (correspondent_id, correspondent_name, correspondent_email, sort_number, is_general, is_favourite, website, refer_to, port_id, country_id) \
                                VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)",
                                values as any
                            );
                            let myCorrespondentId = result["insertId"];

                            if (oCorrespondent["CorrespondentAddressList"]) {
                                oCorrespondent["CorrespondentAddressList"].forEach(async function (oEssedAddressLine: Interfaces.CorrespondentAddressListEntity) {
                                    let oAddressLine = oEssedAddressLine["s_CorrespondentAddress"];
                                    const values = [
                                        u2n(oAddressLine["AddressLine"]),
                                        u2n(oAddressLine["LineNumber"]),
                                        myCorrespondentId,
                                    ]
                                    await doQuery(tx, "INSERT INTO addressline (line, line_number, correspondent_id) \
                                        VALUES (?, ?, ?)",
                                        values as any
                                    );
                                });
                            }

                            if (oCorrespondent["CorrespondentPhoneNumberList"]) {
                                oCorrespondent["CorrespondentPhoneNumberList"].forEach(async function (oEssedPhoneNumber: Interfaces.CorrespondentPhoneNumberListEntity) {
                                    let oPhoneNumber = oEssedPhoneNumber["s_CorrespondentPhoneNumber"];
                                    const values: string[] = [
                                        oPhoneNumber["CorrespondentPhoneNumberID"],
                                        u2n(oPhoneNumber["PhoneNumber"]),
                                        u2n(oPhoneNumber["AdditionalText"]),
                                        u2n(oPhoneNumber["SortNumber"]),
                                        myCorrespondentId
                                    ];
                                    await doQuery(tx, "INSERT INTO correspondent_phone (correspondent_phone_id, phone_number, additional_text, sort_number, correspondent_id) \
                                        VALUES (?, ?, ?, ?, ?)",
                                        values as any
                                    );
                                });
                            }

                            //Contacts
                            if (!oCorrespondent["CorrespondentContactList"]) {
                                return;
                            }
                            await asyncForEach(
                                oCorrespondent["CorrespondentContactList"],
                                async function (oEssedContact: Interfaces.CorrespondentContactListEntity) {
                                    let oContact = oEssedContact["s_CorrespondentContact"];
                                    let contactId = u2n(oContact["CorrespondentContactID"]);
                                    let contactName = u2n(oContact["ContactName"]);
                                    let email = u2n(oContact["EmailAddress"]);
                                    let sortNumber = u2n(oContact["SortNumber"]);
                                    let is_favourite = favouriteContacts.includes(contactId) ? 1 : 0;
                                    const values: string[] = [contactId, contactName, email, is_favourite, sortNumber, myCorrespondentId];

                                    result = await doQuery(tx, "INSERT INTO contact (contact_id, contact_name, contact_email, is_favourite, sort_number, correspondent_id) \
                                        VALUES (?, ?, ?, ?, ?, ?)",
                                        values as any
                                    );

                                    if (!oContact["CorrespondentContactPhoneNumberList"]) {
                                        return;
                                    }

                                    let myContactId = result["insertId"];
                                    await asyncForEach(
                                        oContact["CorrespondentContactPhoneNumberList"],
                                        async function (oEssedPhoneNumber: Interfaces.CorrespondentContactPhoneNumberListEntity) {
                                            let oPhoneNumber = oEssedPhoneNumber["s_CorrespondentContactPhoneNumber"];
                                            const values: string[] = [
                                                u2n(oPhoneNumber["CorrespondentContactPhoneNumberID"]),
                                                u2n(oPhoneNumber["PhoneNumber"]),
                                                u2n(oPhoneNumber["AdditionalText"]),
                                                u2n(oPhoneNumber["SortNumber"]),
                                                u2n(oPhoneNumber["IsMobile"] ? 1 : 0),
                                                myContactId
                                            ];

                                            await doQuery(tx, "INSERT INTO contact_phone (contact_phone_id, phone_number, additional_text, sort_number, is_mobile, contact_id) \
                                                VALUES (?, ?, ?, ?, ?, ?)",
                                                values as any
                                            );
                                        }
                                    );
                                }
                            );
                        }
                    );
                }
            );
        }
    );
    console.info("Refill complete...");
}

