import React from 'react';
import { IonItem, IonSearchbar, NavContext } from '@ionic/react';
import CorrespondentDetail from './CorrespondentDetail';
import { UKPICorrespondent } from '../services/models';
import { ToastContext } from '../services/contexts';

const SearchBar: React.FC = () => {
  let [correspondents, setCorrespondents] = React.useState<UKPICorrespondent[]>([]);
  let [selectValue, setSelectValue] = React.useState<string>("");
  const { message, setMessage } = React.useContext(ToastContext);
  const { navigate } = React.useContext(NavContext);


  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" || e.keyCode == 13) {
      if (e.target.value) {
        window.localStorage.setItem("last-query", e.target.value);
        e.target.blur();
        navigate("/TabSearch");
      }
      else {
        setMessage("Enter some text to search");
      }
    }
  }


  return (
    <>
      <IonItem key="selectCountry" lines="none">
        <IonSearchbar
          value={selectValue}
          placeholder="Search Correspondents:"
          onKeyPress={handleKeyPress}
        >
        </IonSearchbar>
      </IonItem>

      {
        correspondents.map((correspondent) =>
          <div className="vcard correspondents" key={correspondent.id} id={correspondent.id.toString()}>
            <CorrespondentDetail correspondent={correspondent} />
          </div>
        )
      }
    </>
  );
};

export default SearchBar;
