import React from 'react';
import { IonButton, IonContent, IonIcon, IonPage, useIonViewWillEnter } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { globe } from 'ionicons/icons';
import ukpiLogo from "../img/uk-pandi-large.png";

const TabResources: React.FC = () => {
	const launchBrowser = (e: any) => {
    console.dir(e.target);
    window.open(e.target.getAttribute("data-href"), "_system", 'location=yes')
	}


	useIonViewWillEnter(async () => {
	});

  return (
    <IonPage >
      <UKPIHeader />
			<SearchProxy />
      <IonContent fullscreen className="button-list ion-padding">
        <div className="mh-100">
          <div className="flex-row">
            <h2>Resources</h2>
            <p>This service requires an internet connection.</p>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://www.ukpandi.com/news-and-resources/rulebook-2021/">
              <IonIcon icon={globe} /> Rule book
            </IonButton>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://connect.thomasmiller.com/UKPI_ExceptionHandler_CW/Login.aspx">
              <IonIcon icon={globe} /> TM Connect
            </IonButton>
            <section className="ion-text-center">
              <img alt="UKP&amp;I logo" src={ukpiLogo} className="small-logo"  onClick={launchBrowser} data-href="https://www.ukpandi.com/"></img>
            </section>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://www.ukpandi.com/news-and-resources/publications/">
              <IonIcon icon={globe} /> publications
            </IonButton>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://www.ukpandi.com/news-and-resources/club-circulars/">
              <IonIcon icon={globe} /> circulars
            </IonButton>
          </div>
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabResources;
