import React from 'react';
import { IonButton, IonContent, IonIcon, IonPage, useIonViewWillEnter } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { globe } from 'ionicons/icons';
import ukpiLogo from "../img/uk-pandi-large.png";

const TabWebsites: React.FC = () => {
	const launchBrowser = (e: any) => {
    console.dir(e.target);
    window.open(e.target.getAttribute("data-href"), "_system", 'location=yes')
	}



	useIonViewWillEnter(async () => {
	});

  return (
    <IonPage >
      <UKPIHeader />
			<SearchProxy />
      <IonContent fullscreen className="button-list ion-padding">
        <div className="mh-100">
          <div className="flex-row">
            <h2>Websites</h2>
            <p>This service requires an internet connection.</p>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://www.ukpandi.com/">
              <IonIcon icon={globe} /> UKP&amp;I website
            </IonButton>
            <IonButton mode="md" expand="block" color="light" size="large" onClick={launchBrowser} data-href="https://www.thomasmiller.com/">
              <IonIcon icon={globe} /> Thomas Miller
            </IonButton>
          </div>
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabWebsites;
