import { HTTP } from '@ionic-native/http';
import { DirectoryEntry, File, FileEntry } from '@ionic-native/file';
import $ from 'jquery';
import { Capacitor } from '@capacitor/core';

export function handleError(errObj: object, errMsg: string) {
    console.error(errMsg);
    console.error(errObj);
    const toast = document.createElement('ion-toast') as HTMLIonToastElement;
    toast.message = errMsg;
    toast.duration = 2000;
    document.body.appendChild(toast);
    toast.present();
}

export function handleWarning(errObj: object, errMsg: string) {
    console.warn(errMsg);
}

export function s2b(s: string): boolean {
    if (s === "true")
        return true;
    return false;
}

export function n2b(s: number): boolean {
    if (s && s > 0)
        return true;
    return false;
}

export function u2n(x: any) {
    if (x === undefined) {
        return null
    }
    return x;
}


export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}


export async function asyncForEach(a: Array<any>, callback: CallableFunction) {
    for (let index = 0; index < a.length; index++) {
        await callback(a[index], index, a);
    }
}

export function toPath(p: string) {
    let ret = "";
    try {
        if (File.applicationDirectory == null) {
            console.log("File.applicationDirectory is null");
            ret = process.env.PUBLIC_URL + p;
        }
        else {
            ret = File.applicationDirectory + p;
        }
    }
    catch (e) {
        //No cordova§
        console.log("No cordova path");
        ret = process.env.PUBLIC_URL + p;
    }
    return ret;
}

/**
 * gets file from system for mobile
 * 
 * @param directory_path where the file is. If file is in `public/initial-data/foo.json`, then this param should be `public/initial-data` (Pretty sure a trailing slash will break this).
 * @param file_path the name of the file to load.
 * @param r a FileReader that can read the Blob.
*/
export async function fileGet(directory_path: string, file_path: string, encoding: string) : Promise<string> {
    return new Promise(async (resolve, reject) => {
        let absolute_path: string = File.applicationDirectory + directory_path;

        // these are almost like pointers to where the data is, no data is actually loaded.
        let dEntry: DirectoryEntry = await File.resolveDirectoryUrl(absolute_path);
        let fEntry: FileEntry = await File.getFile(dEntry, file_path, {});

        fEntry.file(f => {
            let reader: FileReader = new FileReader();
            // f is an IFile, which is a sub-class of Blob
            // a FileReader can read this and give text back
            reader.onloadend = (evt) => {
                if (reader.result != null) {
                    // readAsText is broken on android, at least
                    let ret = new TextDecoder("utf-8").decode(reader.result as ArrayBuffer);
                    resolve(ret);   
                }
                else {
                    reject(evt);
                }
            };
            reader.readAsArrayBuffer(f);
        });
    });
}


export async function httpGet(url: string, data: object = {}) {
    
    console.info(`Trying to get ${url} with httpGet`);

    // SSL is reporting errors on Android, possibly due to TM Connect not making
    // intermediate certificates available
    // https://stackoverflow.com/questions/6825226/trust-anchor-not-found-for-android-ssl-connection
    //
    if( Capacitor.getPlatform() === 'android') {
        await HTTP.setServerTrustMode("nocheck");
    }
    let ret = {};
    try {
        let response = await HTTP.get(url, data, {});
        ret = JSON.parse(response.data);
    }
    catch (e) {
        //No cordova
        let response = await $.get(url, data, null) as any;
        if (typeof (response) === "string")
            ret = JSON.parse(response);
        else
            ret = response;
    }
    return ret;
}

