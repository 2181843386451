import React, { useContext } from 'react';
import $ from 'jquery';
import { IonButton, IonContent, IonPage, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import '../lib/paddack/paddack.js';
import '../lib/paddack/paddack.css';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { queryNote, updateNote } from '../services/query';
import { ToastContext } from '../services/contexts';

const TabNote: React.FC = () => {
  const [present] = useIonAlert();
  const { message, setMessage } = useContext(ToastContext);

  useIonViewDidEnter(() => {
    $(".paddack").paddack({
      "initialized": function (elt: any, callback: CallableFunction) {
        console.log("loading note");
        $(elt).prop("disabled", true);
        var jqElt = $(elt);
        queryNote("1").then((note) => {
          jqElt.val(note);
          $(elt).prop("disabled", false);
          console.log("Note loaded");
          callback();
        });
      },
      "save": function (elt: any, callback: CallableFunction) {
        // Do nothing
      }
    });
  });

  let saveNote = () => {
    var jqElt = $("#notepad");
    let notes = jqElt.val() as string;
    notes = notes.trim();
    updateNote("1", notes).then(() => {
      setMessage("Note saved");
    });
  }

  let confirmDeleteNote = () => {
    present({
      header: 'Confirm',
      message: 'All deleted text cannot be recovered. Do you still want to proceed?',
      buttons: [
        'Cancel',
        {
          text: 'Ok', handler: async (d) => {
            $("#notepad").val("");
            setMessage("Note deleted");
            await updateNote("1", "");
          }
        },
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
  }

  return (
    <IonPage >
      <UKPIHeader />
      <SearchProxy />
      <IonContent fullscreen className="ion-padding">
        <div className="mh-100">
          <h2>Notepad</h2>
          <textarea id="notepad" className="paddack">

          </textarea>
          <IonButton color="success" onClick={saveNote}>
            Save Note
          </IonButton>
          <IonButton color="danger" onClick={confirmDeleteNote}>
            Delete Note
          </IonButton>
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabNote;
