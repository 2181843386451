export class ActionResult {
	success: boolean;
	message: string;
	constructor(s : boolean, m : string) {
		this.success = s;
		this.message = m;
	} 
}

export interface CorrespondentDatabaseLastUpdatedJson {
	LastUpdateDateTime: string;
}

// Database
export interface CorrespondentDatabaseJson {
	CorrespondentCountryID: string;
	CorrespondentPortList?: (CorrespondentPortListEntity)[] | null;
	CountryDescription: string;
	TenantID: string;
  }

  export interface CorrespondentPortListEntity {
	s_CorrespondentPort: SCorrespondentPort;
  }

  export interface SCorrespondentPort {
	CorrespondentList?: (CorrespondentListEntity)[] | null;
	CorrespondentPortID: string;
	PortDescription: string;
  }

  export interface CorrespondentListEntity {
	s_Correspondent: SCorrespondent;
  }

  export interface SCorrespondent {
	CorrespondentAddressList?: (CorrespondentAddressListEntity)[] | null;
	CorrespondentContactList?: (CorrespondentContactListEntity)[] | null;
	CorrespondentID: string;
	CorrespondentName: string;
	CorrespondentPhoneNumberList?: (CorrespondentPhoneNumberListEntity)[] | null;
	EmailAddress: string;
	IsGeneralCorrespondent: boolean;
	ReferTo: string;
	Sort: number;
	WebsiteAddress: string;
  }

  export interface CorrespondentAddressListEntity {
	s_CorrespondentAddress: SCorrespondentAddress;
  }

  export interface SCorrespondentAddress {
	AddressLine: string;
	CorrespondentAddressID: string;
	LineNumber: number;
  }

  export interface CorrespondentContactListEntity {
	s_CorrespondentContact: SCorrespondentContact;
  }

  export interface SCorrespondentContact {
	ContactName: string;
	CorrespondentContactID: string;
	CorrespondentContactPhoneNumberList?: (CorrespondentContactPhoneNumberListEntity)[] | null;
	EmailAddress: string;
	SortNumber: number;
  }

  export interface CorrespondentContactPhoneNumberListEntity {
	s_CorrespondentContactPhoneNumber: SCorrespondentContactPhoneNumber;
  }
  
  export interface SCorrespondentContactPhoneNumber {
	AdditionalText: string;
	CorrespondentContactPhoneNumberID: string;
	IsMobile: boolean;
	PhoneNumber: string;
	SortNumber: number;
  }
  
  export interface CorrespondentPhoneNumberListEntity {
	s_CorrespondentPhoneNumber: SCorrespondentPhoneNumber;
  }

  export interface SCorrespondentPhoneNumber {
	AdditionalText: string;
	CorrespondentPhoneNumberID: string;
	PhoneNumber: string;
	SortNumber: number;
  }
  

  /* Ship search */
  export interface Ship {
	CertificateReference: string;
	FlagDescription: string;
	GrossTonnage: number;
	IMO: string;
	MemberName: string;
	PolicyReference: string;
	ShipFinderGUID: string;
	VesselName: string;
	VesselType: string;
	YearBuilt: number;
	Attach: string;
	Detach: string;
  }
  