import React from 'react';
import { IonContent, IonItem, IonPage, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import CorrespondentDetail from '../components/CorrespondentDetail';
import {getCountryById, getPortById, queryCorrespondentsForCountry, queryLocalCorrespondentsForPort} from '../services/query'
import {UKPICorrespondent, Country, Port,} from '../services/models'
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import CountrySelect from '../components/CountrySelect';
import PortSelect from '../components/PortSelect';

const TabCorrespondents: React.FC = () => {    
	let [showList, setShowList] = React.useState(false);
	let [correspondents, setCorrespondents] = React.useState<UKPICorrespondent[]>([]);
	let [country, setCountry] = React.useState<null|Country>(Object);
	let [port, setPort] = React.useState<null|Port>(Object);

	const queryPort = async (val: string) => {
        console.log("Selected port " + val);
		if(val) {
			let portData = await getPortById(val);
			setPort(portData);
			setCorrespondents(await queryLocalCorrespondentsForPort(val));
			showResults();
		}
		else {
			setPort(null as any);
		}
		if (!val && !country) {
			hideResults();
		}
    }

	const queryCountry = async (val: string) => {
		console.log(val);
		if(val) {
			let countryData = await getCountryById(val);
			setCountry(countryData);
			await setCorrespondents(await queryCorrespondentsForCountry(val));
			showResults();
		}
		else {
			setCountry(null as any);
			setCorrespondents([]);
		}

		if (!port && !val) {
			hideResults();
			console.log("hide");
		}
    }

	const hideResults = async () => {
		toggleResults(false);
	}

	const showResults = async () => {
		toggleResults(true);
	}

	const toggleResults = async (on:boolean) => {
		setShowList(on);
	}

	useIonViewWillEnter(async () => {
		hideResults();
	});
	
  return (
    <IonPage >
	  <UKPIHeader />
	  <SearchProxy />
		<IonContent fullscreen className="ion-padding">
			<div className="mh-100">
				<h1>Search Correspondents by Country</h1>
				<p>This service does not require an internet connection. </p>
				<CountrySelect onSearchChange={queryCountry} />
				<PortSelect onSearchChange={queryPort} country={country} />
				{showList && (
					correspondents.length > 0 ?
					<div className="results" id="lstCorrespondents">
						{country &&
							<h1>{country.country_name}</h1>
						}

						{correspondents.map( (correspondent) => 
							<div className="vcard correspondents" key={correspondent.id} id={correspondent.id.toString()}>
								<CorrespondentDetail correspondent={correspondent} />
							</div>
						)}
					</div> :
					<IonItem color="danger" key="none">
						No items match your search
					</IonItem>
				)}
			</div>
      	<UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabCorrespondents;
