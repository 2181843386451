import React, {useContext} from 'react';
import logo from "../img/uk-pandi-white.png";
import background from "../img/ship.jpg";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton
} from '@ionic/react';

import { UKPIContext } from '../services/contexts';

const UKPIHeader: React.FC = () => {
	const {loading, setLoading} = useContext(UKPIContext);

	return (
	    <>
			<IonHeader>
				<IonToolbar mode="md">
					<IonTitle style={{ backgroundImage: `url(${background})` }}>
						<img alt="UKP&amp;I" className="ukpilogo" src={logo} />
					</IonTitle>
					<IonButtons slot="end">
						<IonMenuButton mode="md" autoHide={false} />
					</IonButtons>
				</IonToolbar>
				<div className={"progress bar-subheader " + loading} id="progress" ></div>
			</IonHeader>
		</>
	);
};

export default UKPIHeader;
