import { n2b } from "./utils";

export class Country {
	id: number;
	country_name: string;

	constructor(rs: any) {
		this.id = rs.id;
		this.country_name = rs.country_name;
	}
}


export class Port {
	id: number;
	port_id: number;
	port_name: string;
	sort_number: number;
	country_id: number;
	country?: Country;

	constructor(rs: any) {
		this.id = rs.id;
		this.port_id = rs.port_id;
		this.port_name = rs.port_name;
		this.sort_number = parseInt(rs.sort_number);
		this.country_id = rs.country_id;
		this.country = new Country(rs);
	}
}


export class UKPICorrespondent {
	id: number;
	correspondent_id: number;
	correspondent_name: string;
	correspondent_email: string;
	is_general: boolean;
	is_favourite: boolean;
	refer_to: string;
	website: string;
	sort_number: number;
	port_id: number;
	country_id: number;
	port: Port;
	country: Country;

	address_lines: CorrespondentAddressLine[] = [];
	phones: CorrespondentPhone[] = [];
	contacts: UKPIContact[] = [];

	constructor(rs: any) {
		this.id = rs.id;
		this.correspondent_id = rs.correspondent_id;
		this.correspondent_name = rs.correspondent_name;
		this.correspondent_email = rs.correspondent_email;
		this.is_general = n2b(rs.is_general);
		this.is_favourite = n2b(rs.is_favourite);
		this.refer_to = rs.refer_to;
		this.website = rs.website;
		this.sort_number = parseInt(rs.sort_number);
		this.port_id = rs.port_id;
		this.port = new Port(rs);
		this.country_id = rs.country_id;
		this.country = new Country(rs);
	}
}


export class UKPIContact {
	id: number;
	contact_id: number;
	contact_name: string;
	contact_email: string;
	is_favourite: boolean;
	sort_number: number;
	correspondent_id: number;
	correspondent: UKPICorrespondent;

	phones: CorrespondentPhone[] = [];

	constructor(rs: any) {
		this.id = rs.id;
		this.contact_id = rs.contact_id;
		this.contact_name = rs.contact_name;
		this.contact_email = rs.contact_email;
		this.is_favourite = n2b(rs.is_favourite);
		this.sort_number = parseInt(rs.sort_number);
		this.correspondent_id = rs.correspondent_id;
		this.correspondent = new UKPICorrespondent(rs);
	}
}


export class CorrespondentAddressLine {
	id: number;
	line: string;
	line_number: number;
	correspondent_id: number;

	constructor(rs: any) {
		this.id = rs.id;
		this.line = rs.line;
		this.line_number = rs.line_number;
		this.correspondent_id = rs.correspondent_id;
	}
}


export class CorrespondentPhone{
	id: number;
	phone_number: string;
	is_mobile: boolean;
	additional_text: string;
	correspondent_id: number;

	constructor(rs: any) {
		this.id = rs.id;
		this.phone_number = rs.phone_number;
		this.is_mobile = n2b(rs.is_mobile);
		this.additional_text = rs.additional_text;
		this.correspondent_id = rs.correspondent_id;
	}
}


export class ContactPhone {
	id: number;
	phone_number: string;
	is_mobile: boolean;
	additional_text: string;
	contact_id: number;

	constructor(rs: any) {
		this.id = rs.id;
		this.phone_number = rs.phone_number;
		this.is_mobile = rs.is_mobile;
		this.additional_text = rs.additional_text;
		this.contact_id = rs.contact_id;
	}
}
