/* eslint-disable no-multi-str */
import { ContactFieldType, Contacts } from '@ionic-native/contacts';
import { Contact, ContactField, ContactFindOptions, ContactOrganization } from '@ionic-native/contacts';
import { Country, UKPICorrespondent, Port, UKPIContact, CorrespondentPhone } from './models'
import { handleWarning, n2b } from './utils';

var db: any;
var isCordova = false;

if (window.hasOwnProperty("sqlitePlugin")) {
    isCordova = true;
    db = (window as any).sqlitePlugin.openDatabase({
        "name": "test.db",
        "location": "default"
    })

}
else if (window.openDatabase) {
    db = window.openDatabase("ThomasMiller", "1.0", "TinCan Statements", 5000000);
}
else {
    throw new Error("SQLite is required");
}


export function startTransaction(callback: CallableFunction) {
    // Websql doesn't have db.executeSql, but does have working transactions
    if( isCordova ) {
        callback(null);
    }
    else {
        db.transaction(function(tx:any) {
            callback(tx);
        });
    }
}

export function asyncTransaction() {
    return new Promise((resolve, reject) => {
        if( isCordova ) {
            resolve(null);
        }
        else {
            db.transaction(function (tx: any) {
                resolve(tx);
            });
        }
    });
}


export async function doQuery(tx: any, query: string, values: string[] = []) {
    if( false )
        console.log(query);
    /*
    tx.error = (err: any) => {
        console.log('=== transaction error ===')
        console.log(query);
        console.log();
        console.log(err);
    }
    */
    return new Promise((resolve, reject) => {
        if( !isCordova ) {
            tx.executeSql(query, values, (tx: any, rs: any) => {
                resolve(rs);
            },
                (tx: any, error: string) => {
                    console.error(query);
                    console.error(values);
                    console.error(error);
                    reject(error);
                });

        }
        else {
            db.executeSql(query, values, (rs: any) => {
                resolve(rs);
            },
                (error: string) => {
                    console.error(query);
                    console.error(values);
                    console.error(error);
                    reject(error);
                });

        }
    });
}

/*
export async function getDB() {
    if(db) {
        return db;
    }
    document.addEventListener('deviceready', function() {
        db = window.sqlitePlugin.openDatabase({
            "name": "test.db",
            "location": "default"
        });
        return db;
    });
}
*/

/* Database management */
export async function createDbs() {
    let tx = await asyncTransaction();
    console.info("Creating dbs");

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS last_updated( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        data_source VARCHAR(255) NULL,\
        last_updated_date VARCHAR(255));");

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS country( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        country_id INTEGER,\
        country_name VARCHAR(255),\
        sort_number INTEGER\
        );"
    );

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS port( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        port_id INTEGER,\
        port_name VARCHAR(255), \
        sort_number INTEGER,\
        country_id INT(11)\
        );"
    );


    await doQuery(tx, "CREATE TABLE IF NOT EXISTS correspondent( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        correspondent_id INTEGER, \
        correspondent_name VARCHAR(128),\
        correspondent_email VARCHAR(128),\
        is_general INTEGER NOT NULL DEFAULT 0,\
        is_favourite INTEGER NOT NULL DEFAULT 0,\
        refer_to TEXT, \
        website VARCHAR(128), \
        sort_number INTEGER,\
        port_id INT(11), \
        country_id INT(11) \
        );");


    await doQuery(tx, "CREATE TABLE IF NOT EXISTS addressline ( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        line TEXT, \
        line_number INTEGER, \
        correspondent_id INTEGER \
    );");

    await doQuery(tx, "CREATE INDEX IF NOT EXISTS ndx_line_number ON addressline(line_number)");

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS contact( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        contact_id INTEGER, \
        contact_name VARCHAR(128),\
        contact_email VARCHAR(128),\
        is_favourite INTEGER NOT NULL DEFAULT 0,\
        sort_number INTEGER,\
        correspondent_id INTEGER, \
        FOREIGN KEY (correspondent_id) REFERENCES correspondent (id) \
        );");

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS contact_phone( \
            id INTEGER PRIMARY KEY AUTOINCREMENT,\
            contact_phone_id INTEGER, \
            phone_number VARCHAR(255),\
            additional_text VARCHAR(255), \
            contact_id INTEGER, \
            sort_number INTEGER,\
            is_mobile INTEGER NOT NULL DEFAULT 0,\
            FOREIGN KEY (contact_id) REFERENCES contact (id) \
            );"
    );

    /*
await doQuery(tx, "CREATE TABLE IF NOT EXISTS contact_mobile( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        mobile_number VARCHAR(255),\
        additional_text VARCHAR(255), \
        contact_id INTEGER, \
        sort_number INTEGER,\
        FOREIGN KEY (contact_id) REFERENCES contact (id) \
        );"
    );*/

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS correspondent_phone( \
        id INTEGER PRIMARY KEY AUTOINCREMENT,\
        correspondent_phone_id INTEGER, \
        phone_number VARCHAR(255),\
        additional_text VARCHAR(255), \
        sort_number INTEGER,\
        correspondent_id INTEGER, \
        FOREIGN KEY (correspondent_id) REFERENCES correspondent (id) \
        );"
    );

    await doQuery(tx, "CREATE TABLE IF NOT EXISTS note ( \
            id INTEGER PRIMARY KEY AUTOINCREMENT,\
            note TEXT, \
            last_updated DATETIME\
            );"
    );

    // This isn't deleted when the db is wiped but used to restore favourite data
    await doQuery(tx, "CREATE TABLE IF NOT EXISTS favourite ( \
            id INTEGER PRIMARY KEY AUTOINCREMENT,\
            type VARCHAR(32), \
            ukpi_id INTEGER \
            );"
    );
}


export async function deleteDbs() {
    console.info("Dropping dbs");
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE addressline;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE company;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE contact;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE contact_phone;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE correspondent;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE correspondent_mobile;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE correspondent_phone;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE last_updated;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE correspondent_phone;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE port;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
    try {
        let tx = await asyncTransaction();
        await doQuery(tx, "DROP TABLE note;");
    }
    catch (e) {
        handleWarning(e, e.message);
    }
}


export async function emptyDbs(tx: any) {
    console.info("Emptying contact dbs");
    await doQuery(tx, "DELETE FROM addressline;");
    await doQuery(tx, "DELETE FROM country;");
    await doQuery(tx, "DELETE FROM port;");
    await doQuery(tx, "DELETE FROM correspondent;");
    await doQuery(tx, "DELETE FROM contact;");
    await doQuery(tx, "DELETE FROM contact_phone;");
    await doQuery(tx, "DELETE FROM correspondent_phone;");
    await doQuery(tx, "DELETE FROM note;");
}


export async function wipeAndRebuildDbs() {
    await deleteDbs();
    await createDbs();
}


/* Read data */
export async function rsToCorrespondents(tx: any, rs: any) {
    let ret = [];
    for (let i = 0; i < rs.rows.length; i++) {
        var correspondent = new UKPICorrespondent(rs.rows.item(i));
        if (correspondent.refer_to) {
            ret.push(correspondent);
            continue;
        }
        let correspondent_address_rs = await doQuery(tx, "SELECT * FROM addressline WHERE correspondent_id=? ORDER BY line_number", [correspondent.id.toString()]) as any;
        correspondent.address_lines = [];
        for (let k = 0; k < correspondent_address_rs.rows.length; k++) {
            correspondent.address_lines.push(correspondent_address_rs.rows.item(k));
        }

        let correspondent_phones_rs = await doQuery(tx, "SELECT * FROM correspondent_phone WHERE correspondent_id=?", [correspondent.id.toString()]) as any;
        correspondent.phones = [];
        for (let k = 0; k < correspondent_phones_rs.rows.length; k++) {
            correspondent.phones.push(correspondent_phones_rs.rows.item(k));
        }

        let correspondent_contacts_rs = await doQuery(tx, "SELECT * FROM contact WHERE correspondent_id=?", [correspondent.id.toString()]) as any;
        correspondent.contacts = await rsToContacts(tx, correspondent_contacts_rs);

        ret.push(correspondent);
    }
    return ret;
}


export async function rsToContacts(tx: any, rs: any) {
    let ret = [];
    for (let i = 0; i < rs.rows.length; i++) {
        var contact = new UKPIContact(rs.rows.item(i));
        let contact_phones_rs = await doQuery(tx, "SELECT * FROM contact_phone WHERE contact_id=?", [contact.id.toString()]) as any;
        contact.phones = [];
        for (let k = 0; k < contact_phones_rs.rows.length; k++) {
            contact.phones.push(contact_phones_rs.rows.item(k));
        }
        ret.push(contact);
    }
    return ret;
}


export async function queryLastUpdated() {
    let tx = await asyncTransaction();
    let rs = await doQuery(tx, "SELECT last_updated_date FROM last_updated ORDER BY last_updated_date DESC LIMIT 1;", []) as any;
    if (rs && rs.rows.length > 0) {
        var sLastUpdated = rs.rows.item(0).last_updated_date;
        return sLastUpdated;
    }
}


export async function queryLocalCorrespondentsForPort(val: string) {
    let tx = await asyncTransaction();
    let correspondent_rs = await doQuery(tx,
        "SELECT correspondent.*,port.port_name, country.country_name FROM correspondent \
        JOIN port ON correspondent.port_id = port.id \
        JOIN country ON port.country_id = country.id \
        WHERE correspondent.port_id=? \
        ORDER BY sort_number", [val.toString()]) as any;
    return rsToCorrespondents(tx, correspondent_rs);
}


export async function queryCorrespondentsForCountry(countryId: string) {
    let tx = await asyncTransaction();
    let correspondent_rs = await doQuery(
        tx,
        "SELECT correspondent.*,port.port_name, country.country_name FROM correspondent \
            JOIN port ON correspondent.port_id = port.id \
            JOIN country ON port.country_id = country.id \
            WHERE correspondent.country_id=? ORDER BY port.port_name, sort_number",
        [countryId]
    ) as any;
    return rsToCorrespondents(tx, correspondent_rs);
}


export async function queryLocalContactsLikeName(val: string) {
    let tx = await asyncTransaction();
    let percentVal = "%" + val.trim().toLowerCase() + "%";
    let params = [percentVal, percentVal, percentVal, percentVal, percentVal];

    let contact_rs = await doQuery(tx,
        "SELECT DISTINCT correspondent.*, port.port_name, country.country_name \
        FROM correspondent \
        LEFT JOIN contact ON contact.correspondent_id = correspondent.id \
        LEFT JOIN port ON correspondent.port_id = port.id \
        LEFT JOIN country ON port.country_id = country.id \
        WHERE correspondent.correspondent_name LIKE ? \
        OR correspondent.correspondent_email LIKE ? \
        OR contact.contact_name LIKE ? \
        OR country.country_name LIKE ? \
        OR port.port_name LIKE ? \
        ORDER BY refer_to, contact_name LIMIT 50", params) as any;
    return rsToCorrespondents(tx, contact_rs);
}


export async function queryContactsForCorrespondent(val: string) {
    let tx = await asyncTransaction();
    let ret = [];
    let contact_rs = await doQuery(tx, "SELECT * FROM contacts WHERE correspondent_id=? ORDER BY sort_number", [val.toString()]) as any;
    for (let i = 0; i < contact_rs.rows.length; i++) {
        var contact = new UKPIContact(contact_rs.rows.item(i));
        var contact_phones_rs = await doQuery(tx, "SELECT * FROM contact_phone WHERE contact_id=? ORDER BY sort_number", [contact.id.toString()]) as any;
        contact.phones = [];
        for (var k = 0; k < contact_phones_rs.rows.length; k++) {
            contact.phones.push(
                new CorrespondentPhone(contact_phones_rs.rows.item(k))
            );
        }
        ret.push(contact);
    }
    return ret;
}


export const getPortById = async (portId: string) => {
    let tx = await asyncTransaction();
    let port_rs = await doQuery(tx, "SELECT * FROM port JOIN country ON port.country_id=country.id WHERE port.id=?", [portId]) as any;
    let portData = new Port(port_rs.rows.item(0));
    let countryData = new Country(port_rs.rows.item(0));
    portData.country = countryData;
    return portData;
}


export const getCountryById = async (countryId: string) => {
    let tx = await asyncTransaction();
    let rs = await doQuery(tx, "SELECT * FROM country WHERE id=?", [countryId]) as any;
    let countryData = new Country(rs.rows.item(0));
    return countryData;
}


export const queryPortsLikeName = async (filter: string, countryId?: string) =>
{
    let ret = [];
    let params = []
    let query = "SELECT port.id, port_name, country_name FROM port " +
                    "JOIN country ON port.country_id = country.id WHERE 1 ";
    if (filter) {
        query += "AND port_name != '' AND port_name LIKE ? ";
        params.push("%" + filter + "%");
    }
    if(countryId) {
        query += "AND country.id = ?";
        params.push(countryId);
    }
    query += " ORDER BY port_name";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, params) as any;
    if (res.rows.length > 0) {
        for (let i = 0; i < res.rows.length; i++) {
            let port = new Port(res.rows.item(i));

            // Sometimes countries refer to other countries. 
            // In this case, PortDescription exists and is empty
            if(!port.port_name)
                continue
            ret.push(port);
        }
    }
    return ret;
}


export async function queryCountriesLikeName(filter: string) {
    console.log(`using filter: ${filter}`);
    let query = null;
    let countries = [];
    /* top options added on android by default */
    let params = []
    if (filter) {
        query = "SELECT id, country_name FROM country WHERE country_name != '' AND country_name LIKE ? ORDER BY country_name";
        params.push("%" + filter + "%");
    }
    else {
        query = "SELECT id, country_name FROM country WHERE country_name != '' ORDER BY country_name";
    }

    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, []) as any;
    if (res.rows.length > 0) {
        for (let i = 0; i < res.rows.length; i++) {
            let c = new Country(res.rows.item(i));
            countries.push(c);
        }
    }
    return countries;
}


export async function queryCountryForPort(portId: string) {
    let query = "SELECT * FROM port JOIN country ON port.country_id=country.id WHERE port.id=?";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, [portId]) as any;
    if (res.rows.length > 0) {
        for (let i = 0; i < res.rows.length; i++) {
            let c = new Country(res.rows.item(i).id);
            return c;
        }
    }
}


export async function createNote(noteId: string) {
    let tx = await asyncTransaction();
    let query = "SELECT * FROM note where id=? LIMIT 1";
    let res = await doQuery(tx, query, [noteId]) as any;
    if( res.rows.length === 0 ) {
        query = "INSERT INTO note (id, note, last_updated) VALUES (?, ?, DATE('now'))";
        res = await doQuery(tx, query, [noteId, ""]) as any;
        return res;
    }
}


export async function updateNote(noteId: string, note: string) {
    let query = "UPDATE note SET note=?, last_updated=DATE('now') WHERE id=?";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, [note, noteId]) as any;
    return res;
}

export async function queryNote(noteId: string) {
    let query = "SELECT * FROM note WHERE id=? LIMIT 1";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, [noteId]) as any;
    if( res.rows.length > 0 ) {
        return res.rows.item(0).note;
    }
    else
    {
        await createNote(noteId);
        return "";
    }
}


export async function toggleFavouriteCorrespondent(correspondentId: string) {
    let tx = await asyncTransaction();
    let query = "SELECT is_favourite FROM correspondent WHERE correspondent_id=? LIMIT 1";
    let res = await doQuery(tx, query, [correspondentId]) as any;
    let isFav = !n2b(res.rows.item(0).is_favourite);
    query = "UPDATE correspondent SET is_favourite=? WHERE correspondent_id=?";
    await doQuery(tx, query, [isFav ? "1" : "0", correspondentId]);
    if (isFav) {
        query = "INSERT INTO favourite (type, ukpi_id) VALUES(?, ?)";
    }
    else {
        query = "DELETE FROM favourite WHERE type=? AND ukpi_id=?";
    }
    await doQuery(tx, query, ["correspondent", correspondentId]);
    return isFav;
}


export async function toggleFavouriteContact(contactId: string) {
    let tx = await asyncTransaction();
    let query = "SELECT is_favourite FROM contact WHERE contact_id=? LIMIT 1";
    let res = await doQuery(tx, query, [contactId]) as any;
    let isFav = !n2b(res.rows.item(0).is_favourite);
    query = "UPDATE contact SET is_favourite=? WHERE contact_id=?";
    await doQuery(tx, query, [isFav ? "1" : "0", contactId]);
    if (isFav) {
        query = "INSERT INTO favourite (type, ukpi_id) VALUES(?, ?)";
    }
    else {
        query = "DELETE FROM favourite WHERE type=? AND ukpi_id=?";
    }
    await doQuery(tx, query, ["contact", contactId]);
    return isFav;
}


export async function queryContact(fullName : string) : Promise<Contact> {
    var options      = new ContactFindOptions();
    options.filter   = fullName;
    options.multiple = false;
    console.log("Qurying for contact " + fullName);
    let result = await new Contacts().find(["displayName", "name", "nickname"], options);

    console.log("Result for contact " + result + " " + result[0]);
    return result[0];
}

export async function contactToCordovaContact(contactId: string, existingContact: Contact | null) {
    let tx = await asyncTransaction();
    let query = "SELECT * FROM contact \
                LEFT JOIN correspondent ON contact.correspondent_id = correspondent.id \
                LEFT JOIN country ON correspondent.country_id = country.id \
                WHERE contact_id=? LIMIT 1";
    let res = await doQuery(tx, query, [contactId]) as any;
    if( res.rows.length == 0 )
        return null;
    else {
        let c = new Contact();
        if( existingContact != null )
        {
            c = existingContact;
        }
        let ukpiContacts = await rsToContacts(tx, res);
        console.error(c.id);
        c.displayName = ukpiContacts[0].contact_name;
        for (let i = 0; i < ukpiContacts[0].phones.length; i++) {
            let cp = new ContactField(
                ukpiContacts[0].phones[i].is_mobile?"mobile":"work",
                ukpiContacts[0].phones[i].phone_number,
                false
            );
            c.phoneNumbers = [cp];
        }
        c.emails = [new ContactField(
            "email", ukpiContacts[0].contact_email, true)];
        
        res = await doQuery(tx, "SELECT * FROM addressline WHERE correspondent_id=?",
            [ukpiContacts[0].correspondent_id] as any
        );
        if( res.rows.length > 0 )
        {
            // TODO: this.
            c.addresses = [];
        }
        c.organizations = [];
        let co = new ContactOrganization();
        co.name = ukpiContacts[0].correspondent.correspondent_name;
        co.type = "work";
        c.organizations = [co];
        console.dir(c);
        c.note = "Created by UKP&I Correspondents app";
        let cu = new ContactField();
        cu.value = ukpiContacts[0].correspondent.website;
        cu.type = "work";
        cu.pref = false;
        c.urls = [cu];
        c.save();
        return c;
    };
}

export async function queryFavouriteCorrespondents() {
    let query = "SELECT correspondent.*,port.port_name, country.country_name FROM correspondent \
        JOIN port ON correspondent.port_id = port.id \
        JOIN country ON port.country_id = country.id \
        WHERE correspondent.is_favourite IS TRUE \
        ORDER BY correspondent.sort_number, correspondent_name LIMIT 20";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, []) as any;
    return await rsToCorrespondents(tx, res);
}


export async function queryFavouriteContacts() {
    let query = "SELECT correspondent.*, port.port_name, country.country_name \
        FROM contact \
        JOIN correspondent ON contact.correspondent_id = correspondent.id \
        JOIN port ON correspondent.port_id = port.id \
        JOIN country ON port.country_id = country.id \
        WHERE contact.is_favourite IS TRUE \
        GROUP BY correspondent.id \
        ORDER BY contact.sort_number, contact_name LIMIT 20";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, []) as any;
    return await rsToCorrespondents(tx, res);
}

/*
export async function queryFavouriteContact()
{
    let query = "SELECT * FROM contact WHERE is_favourite=1 ORDER BY contact_name LIMIT 20";
    let tx = await asyncTransaction();
    let res = await doQuery(tx, query, []) as any;
    return res
}*/


export async function queryFavouriteTable(tx: any) {
    let query = "SELECT * FROM favourite WHERE type='correspondent' ORDER BY ukpi_id";
    let res = await doQuery(tx, query, []) as any;
    let crispret = []
    if (res.rows) {
        for (let i = 0; i < res.rows.length; i++) {
            crispret.push(res.rows.item(i).ukpi_id);
        }
    }

    query = "SELECT * FROM favourite WHERE type='contact' ORDER BY ukpi_id";
    res = await doQuery(tx, query, []) as any;
    let contactret = []
    if (res.rows) {
        for (let i = 0; i < res.rows.length; i++) {
            contactret.push(res.rows.item(i).ukpi_id);
        }
    }
    return [crispret, contactret];
}
