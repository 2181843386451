import React from 'react';
import logo from "../img/ukpandi-tm.png";
import tw from "../img/twitter.svg";
import lin from "../img/linkedin.svg";
import { IonCol, IonGrid, IonRow } from '@ionic/react';

const UKPIFooter: React.FC = () => {
	return (
	    <>
			<footer>
				<IonGrid>
					<IonRow>
						<IonCol>
					<img src={logo} alt="UKP&amp;I is managed by Thomas Miller" />
						</IonCol>
						<IonCol>
							<p>
							© {new Date().getFullYear()} Thomas Miller Group. All rights reserved.
							</p>
					<a href="https://www.linkedin.com/company/uk-p&i-club/"><img alt="[linkedIn]" src={lin} /></a>
					&nbsp;
					&nbsp;
					&nbsp;
					<a href="https://twitter.com/ukpandi"><img alt="[twitter]" src={tw} /></a>
					
						</IonCol>
					</IonRow>
				</IonGrid>
			</footer>
		</>
	);
};

export default UKPIFooter;
