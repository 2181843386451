import React from 'react';
import { IonContent, IonItem, IonList, IonPage, useIonViewWillEnter } from '@ionic/react';
import CorrespondentDetail from '../components/CorrespondentDetail';
import { queryFavouriteContacts, queryFavouriteCorrespondents } from '../services/query';
import { UKPICorrespondent } from '../services/models';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';

const TabFavourite: React.FC = () => {
	let [showList, setShowList] = React.useState(false);
	let [correspondents, setCorrespondents] = React.useState<UKPICorrespondent[]>([]);
	let [contacts, setContacts] = React.useState<UKPICorrespondent[]>([]);

	const query = async () => {
		let crisps = await queryFavouriteCorrespondents();
		console.dir(crisps);
		setCorrespondents(crisps);

		let contacts = await queryFavouriteContacts();
		setContacts(contacts);
	}

	useIonViewWillEnter(async () => {
		await query();
		setShowList(true);
	});

	return (
		<IonPage >
			<UKPIHeader />
			<SearchProxy />
			<IonContent fullscreen className="ion-padding">
				<div className="mh-100">
					<div className="flex-row">
						<h2>
							Favourite Correspondents
						</h2>
						{showList && <>
							{correspondents.length === 0 ?
								<IonItem color="warning" key="correspondentNone">
									You have no favourite correspondents
							</IonItem>
								:
								<IonList className="ion-no-padding results" id="lstCorrespondents">
									{correspondents.map((correspondent) =>
										<IonItem className="ion-no-padding correspondents" key={correspondent.id}>
											<CorrespondentDetail correspondent={correspondent} />
										</IonItem>
									)}
								</IonList>
							}
						</>}
					</div>
				</div>
				<UKPIFooter />
			</IonContent>
		</IonPage>
	);
};

export default TabFavourite;
