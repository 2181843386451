import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';


const TabManage: React.FC = () => {
  return (
    <IonPage >
      <UKPIHeader />
			<SearchProxy />
      <IonContent fullscreen className="ion-padding">
          <h1>Checking Data</h1>
          <p>This app checks for updated correspondents data every time it is loaded.  This page makes this check manually.</p>
          <p>This app ships with an old copy of the data.  We'll examine and use that if you've never been online while using this app. The bar above will go yellow when that check is complete.</p>
          <p>If you are online, we'll check for more up-to-date data on our servers and load that. When that's done, the bar above will go green.</p>
          <p className="progress"></p>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabManage;


