import React from 'react';
import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  NavContext,
  useIonToast
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';


import { peopleOutline, homeOutline, alertCircleOutline, boatOutline, starOutline, pencilOutline, cogOutline, globeOutline, albumsOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* UKPI stuff */
import { useState, useEffect } from "react";
import { UKPIContext, ToastContext } from "./services/contexts"
import { createDbs, createNote } from './services/query';
import { dateCheckLocal, dateCheckRemote } from './services/api';

import './theme/style.css';

import TabHome from './pages/TabHome';
import TabCheck from './pages/TabCheck';
import TabContact from './pages/TabContact';
import TabCorrespondents from './pages/TabCorrespondents';
import TabManage from './pages/TabManage';
import TabShips from './pages/TabShips';
import TabNote from './pages/TabNote';
import TabFavourite from './pages/TabFavourite';
import TabResources from './pages/TabResources';
import TabWebsites from './pages/TabWebsites';
import TabSearch from './pages/TabSearch';
import { Redirect, Route } from 'react-router';

const App: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const [loading, setLoading] = useState("");
  const progress = { loading, setLoading };
  const [message, setMessage] = useState("");
  const toastMessage = { message, setMessage };

  useEffect(() => {
    async function dateCheck() {
      setLoading("loading");
      if (navigator.onLine) {
        console.log("Online, check remote date");
        let result = await dateCheckRemote();
        setMessage(result.message);
        setLoading("ready");
      }
      else {
        console.log("Offline, check local date");
        setLoading("loading");
        let result = await dateCheckLocal()
        setMessage(result.message);
        setLoading("ready-local");
      }
    }

    async function initBar() {
      console.log("Ready");
      setMessage("Loading data...");
      await createDbs();
      await dateCheck();
      console.log("Done Ready");
      try {
        await createNote("1");
      }
      catch (e) {

      }
    }
    initBar();
  }, []);

  async function updateToast() {
    console.dir("Toast " + message);
    if (!message)
      return;
    await dismiss();
    await present({
      message: message,
      duration: 2000,
      //onDidDismiss: () => {setMessage("");}
    });
  }

  useEffect(() => {
    updateToast();
  }, [message]);

  return (
    <IonApp>
      <IonReactRouter>
        <IonMenu side="end" contentId="main-router" className="main-menu" menuId="main-menu">
          <IonHeader>
            <IonToolbar mode="md" color="secondary">
              <IonTitle >Menu</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonMenuToggle menu="main-menu" auto-hide="false">
              <IonItem key="about" routerLink="/TabHome">
                <IonIcon icon={homeOutline}></IonIcon> Home
              </IonItem>
              <IonItem key="correspondents" routerLink="/TabCorrespondents">
                <IonIcon icon={peopleOutline}></IonIcon> Correspondent Search
              </IonItem>
              <IonItem key="contact" routerLink="/TabContact">
                <IonIcon icon={alertCircleOutline}></IonIcon> Emergency Contacts
              </IonItem>
              <IonItem key="ship-search" routerLink="/TabShips">
                <IonIcon icon={boatOutline}></IonIcon> Ship Search
              </IonItem>
              <IonItem key="favourite" routerLink="/TabFavourite">
                <IonIcon icon={starOutline}></IonIcon> Favourites
              </IonItem>
              <IonItem key="note" routerLink="/TabNote">
                <IonIcon icon={pencilOutline}></IonIcon> Notepad
              </IonItem>
              <IonItem key="resources" routerLink="/TabResources">
                <IonIcon icon={albumsOutline}></IonIcon> Resources
              </IonItem>
              <IonItem key="websites" routerLink="/TabWebsites">
                <IonIcon icon={globeOutline}></IonIcon> Websites
              </IonItem>
              <IonItem key="manage" routerLink="/TabManage">
                <IonIcon icon={cogOutline}></IonIcon> Manage Data/Settings
              </IonItem>
            </IonMenuToggle>
          </IonContent>
        </IonMenu>
        <ToastContext.Provider value={toastMessage}>
          <UKPIContext.Provider value={progress}>
            <IonTabs>
              <IonRouterOutlet id="main-router">
                <Route path="/TabHome" component={TabHome} exact />
                <Route path="/TabCheck" component={TabCheck} exact />
                <Route path="/TabContact" component={TabContact} exact />
                <Route path="/TabCorrespondents" component={TabCorrespondents} exact />
                <Route path="/TabManage" component={TabManage} exact />
                <Route path="/TabShips" component={TabShips} exact />
                <Route path="/TabNote" component={TabNote} exact />
                <Route path="/TabResources" component={TabResources} exact />
                <Route path="/TabWebsites" component={TabWebsites} exact />
                <Route path="/TabFavourite" component={TabFavourite} exact />
                <Route path="/TabSearch" component={TabSearch} exact />
                <Redirect exact from="/" to="/TabHome" />
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="TabHome" href="/TabHome">
                  <IonIcon icon={homeOutline}></IonIcon>
                  <IonLabel>Home<br />&nbsp;</IonLabel>
                </IonTabButton>
                <IonTabButton tab="TabCorrespondents" href="/TabCorrespondents">
                  <IonIcon icon={peopleOutline}></IonIcon>
                  <IonLabel>Correspondents<br />&nbsp;</IonLabel>
                </IonTabButton>
                <IonTabButton tab="TabContact" href="/TabContact">
                  <IonIcon icon={alertCircleOutline}></IonIcon>
                  <IonLabel>Emergency<br />Contacts</IonLabel>
                </IonTabButton>
                <IonTabButton tab="TabShips" href="/TabShips">
                  <IonIcon icon={boatOutline}></IonIcon>
                  <IonLabel>Ship<br />Search</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </UKPIContext.Provider>
        </ToastContext.Provider>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
