import React from 'react';
import { IonButton, IonContent, IonIcon, IonList, IonPage } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { call } from 'ionicons/icons';

const TabContact: React.FC = () => {
  return (
    <IonPage >
      <UKPIHeader />
			<SearchProxy />
      <IonContent fullscreen className="ion-padding">
        <div className="mh-100">
          <h2>Emergency Contacts</h2>
          
          <p>
              <strong>Please note: Team mail addresses are not regularly monitored out of hours – if you have an emergency after hours or if you have not received a response to a message sent to the team mail address please contact the duty executive.</strong>
          </p>
          <p>
              If you need assistance out of hours and at weekends please call one of the following numbers to contact an experienced duty claims executive, available 24 hours a day:
          </p>
          
          <h3>Europe, Middle East or Africa:</h3>
					<IonList className="phones">
            <IonButton mode="md" color="danger" href="tel:00447768143884">
                <IonIcon icon={call} />
                +44 7768 143 884
            </IonButton> or &nbsp;
            <IonButton mode="md" color="danger" href="tel:00306946685491">
                <IonIcon icon={call} />
                +30 6946 68 54 91
            </IonButton>
            
            <h3>North and South America:</h3>
            <IonButton mode="md" color="danger" href="tel:0012013151755">
                <IonIcon icon={call} />
                +1 201 315 1755
            </IonButton>
            
            <h3>Far East and Australasia:</h3>
            <IonButton mode="md" color="danger" href="tel:0085291955459">
                <IonIcon icon={call} />
                +852 9195 5459
            </IonButton>
          </IonList>
          <p>
              To enable the swiftest response to an incident, Members are recommended to call the number which is closest to the time zone in which the incident has occurred. Please note that team mail addresses are not regularly monitored out of hours, so any matter requiring immediate attention should be communicated by telephone.
          </p>
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabContact;
