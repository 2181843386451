import {createContext} from 'react';

const UKPIContext = createContext({
  loading: "",
  setLoading: (s: string) => {}
});

const ToastContext = createContext({
  message: "",
  setMessage: (s: string) => {}
});

export { UKPIContext, ToastContext };