import React, { useContext } from 'react';
import { IonContent, IonPage, IonButton, IonSearchbar, IonItem, IonIcon, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import { queryLocalContactsLikeName } from '../services/query';
import CorrespondentDetail from '../components/CorrespondentDetail';
import { UKPICorrespondent } from '../services/models';
import { ToastContext } from '../services/contexts';
import { searchCircle } from 'ionicons/icons';

const SearchBar: React.FC = () => {
	let [showList, setShowList] = React.useState(false);
  let [correspondents, setCorrespondents] = React.useState<UKPICorrespondent[]>([]);
  let [searchVal, setSearchVal] = React.useState("");
  let searchButton = React.createRef<HTMLIonButtonElement>();
  const { message, setMessage } = useContext(ToastContext);


  const handleKeyPress = (e: any) => {
    setSearchVal(e.target.value);
    if (e.key === "Enter" || e.keyCode == 13) {
      console.log("Keypress enter for " + e.target.value);
      doQuery(e.target.value);
      e.target.blur();
    }
    else {
    }
  }


  const doQuery = async (q: string) => {
    if (q === "") {
      // handleReset();
      setShowList(false);
      setMessage("Enter some text to search");
    }
    else {
      let rs = await queryLocalContactsLikeName(q);
      if (rs.length > 0) {
        setCorrespondents(rs);
        let scroller = document.getElementById("scroller");
        if (scroller !== null)
          scroller.scrollTo(0, 0);
      }
      else {
        setCorrespondents([]);
      }
      setShowList(true);
    }
  }


  useIonViewDidEnter(async () => {
    if (window.localStorage.getItem("last-query") !== null) {
      await doQuery(window.localStorage["last-query"]);
      setSearchVal(window.localStorage["last-query"]);
      window.localStorage.removeItem("last-query");
    }
  });


  return (
    <IonPage>
      <UKPIHeader />
      <IonItem key="selectCountry" lines="none">
        <IonSearchbar
          value={searchVal}
          placeholder="Search Correspondents and Contacts:"
          onKeyPress={handleKeyPress}
        >
        </IonSearchbar>
      </IonItem>
      <IonContent fullscreen className="ion-padding">
        <div className="mh-100">
          <h1>Search Correspondents &amp; Contacts</h1>
          <p>This service does not require an internet connection. </p>
          {showList && (
            correspondents.length > 0 ?
              <>
              {
                correspondents.map((correspondent) =>
                  <div className="vcard correspondents" key={correspondent.id} id={correspondent.id.toString()}>
                    <CorrespondentDetail correspondent={correspondent} />
                  </div>
                ) 
              } </>:
            <IonItem color="danger" key="none">
              No items match your search
            </IonItem>
				  )}
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default SearchBar;
