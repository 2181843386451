import React from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import SearchProxy from '../components/SearchProxy';
import { UKPICorrespondent } from '../services/models';
import ukpiLogo from "../img/uk-pandi.png";
import { globe, pencilOutline, starOutline } from 'ionicons/icons';

const TabHome: React.FC = () => {
  let [correspondents, setCorrespondents] = React.useState<UKPICorrespondent[]>([]);

  const launchBrowser = (e: any) => {
    console.dir(e.target);
    window.open(e.target.getAttribute("data-href"), "_system", 'location=yes')
  }

  return (
    <IonPage >
      <UKPIHeader />
      <SearchProxy />
      <IonContent className="full-height">
        <IonGrid className="h-100">
          <IonRow className="h-50">
            <IonCol>
              <IonButton mode="md" expand="block" color="light" size="large" className="h-100 homeItem" onClick={launchBrowser} data-href="https://connect.thomasmiller.com/UKPI_ExceptionHandler_CW/Login.aspx">
                <img alt="UKP&amp;I logo" src={ukpiLogo} className="small-logo"></img>
              &nbsp;TM Connect
            </IonButton>
            </IonCol>
            <IonCol>
              <IonButton mode="md" expand="block" color="light" size="large" className="h-100 homeItem" onClick={launchBrowser} data-href="https://www.ukpandi.com/news-and-resources/rulebook-2021/">
                <IonIcon icon={globe} />&nbsp;Rule book
            </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="h-50">
            <IonCol>
              <IonButton mode="md" color="light" size="large" className="h-100 homeItem" expand="block" routerLink="/TabFavourite">
                <IonIcon icon={starOutline}></IonIcon>&nbsp;Favourites
            </IonButton>
            </IonCol>
            <IonCol>
              <IonButton mode="md" color="light" size="large" className="h-100 homeItem" expand="block" routerLink="/TabNote">
                <IonIcon icon={pencilOutline}></IonIcon>&nbsp;Notepad
            </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <UKPIFooter />
      </IonContent>
    </IonPage >
  );
};

export default TabHome;
