import React, { useRef, useContext } from 'react';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonIcon, IonItem, IonList, IonPage, IonSearchbar, IonSpinner, useIonViewWillEnter } from '@ionic/react';
import UKPIHeader from '../components/UKPIHeader';
import UKPIFooter from '../components/UKPIFooter';
import { fetchShips } from '../services/api';
import { Ship } from '../services/interfaces';
import { ToastContext } from '../services/contexts';

const TabShips: React.FC = () => {
  let [showList, setShowList] = React.useState(false);
  let [showSpinner, setShowSpinner] = React.useState(false);
  const { message, setMessage } = useContext(ToastContext);
  let [ships, setShips] = React.useState<Ship[]>([]);
  const shipSearchBar = useRef<HTMLIonSearchbarElement>(null);

  const handleSubmit = async (e: any) => {
    if(e.key === "Enter" || e.keyCode == 13) {
      e.target.blur();
      let searchText = "";
      if (shipSearchBar.current && shipSearchBar.current.value)
        searchText = shipSearchBar.current.value;
      if(!searchText) {
        setMessage("Please enter a query");
        setShowList(false);
        return;
      }

      setShowSpinner(true);
      try {
        let ships = await fetchShips(searchText);
        setShowSpinner(false);
        setShowList(true);
        setShips(ships);
      }
      catch (e) {
        setShowSpinner(false);
        setMessage("Failed to retrieve ship data");
      }
    }
  }

  useIonViewWillEnter(async () => {
  });

  return (
    <IonPage >
      <UKPIHeader />
      <IonContent fullscreen className="ion-padding">
        <div className="mh-100">
          <h2>Ship Search</h2>

          <p>This Ship Finder is updated on a daily basis. Members who need to advise the Club of updates to their recorded ships' details should advise their usual underwriting contact.</p>

          <p>If you are unable to find a ship on this list it may be entered with another P&amp;I Club. The Equasis database aims to record the P&I clubs for all the world's ships and provides a quick and easy way to check the current insurer of these risks.</p>

          <IonSearchbar
            placeholder="Search ships"
            ref={shipSearchBar}
            onKeyPress={handleSubmit}
            >
          </IonSearchbar>
          {showSpinner &&
            <IonSpinner className="spinner" name="dots" />
          }
          {showList &&
            <IonList>
              {
                ships.length > 0 ?
                  ships.map((s) =>
                    <IonItem key={s.ShipFinderGUID} className="ion-no-padding">
                      <IonCard className="w-100 ion-no-padding">
                        <IonCardHeader>
                          <IonCardTitle>{s.VesselName} ({s.VesselType}, {s.YearBuilt})</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          <ul>
                            <li>IMO: {s.IMO}</li>
                            <li>Flag: {s.FlagDescription}</li>
                            <li>Tonnage: {s.GrossTonnage}</li>
                            <li>Member name: {s.MemberName}</li>
                            <li>Attach: {s.Attach.split("T")[0]}</li>
                            <li>Detach: {s.Detach.split("T")[0]}</li>
                          </ul>
                        </IonCardContent>
                      </IonCard>
                    </IonItem>
                  ) :
                  <IonItem color="danger" key="none">
                    No items match your search
                      </IonItem>
              }
            </IonList>
          }
        </div>
        <UKPIFooter />
      </IonContent>
    </IonPage>
  );
};

export default TabShips;
