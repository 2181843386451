import React from 'react';
import { IonSelect, IonItem, useIonViewWillEnter, IonSelectOption, useIonViewDidLeave } from '@ionic/react';
import { queryCountriesLikeName } from '../services/query'
import { Country } from '../services/models'

interface ContainerProps {
	onSearchChange: CallableFunction;
}

const CountrySelect: React.FC<ContainerProps> = ({ onSearchChange }) => {
	let [countries, setCountries] = React.useState<Country[]>([]);
	let countryRef = React.createRef<HTMLIonSelectElement>();

	const handleChange = async (e:any) => {
		const query = e.detail.value;
		if( query === "" ) {
			handleReset();
		}
		else {
			await onSearchChange(query);
		}
	}

	const handleReset = async () => {
		if( countryRef.current )
		{
			countryRef.current.value = "";
		}
		await onSearchChange("");
	}

	const getCountries = async (query: string) => {
		let p = await queryCountriesLikeName(query);
		setCountries(p);
	}

	useIonViewWillEnter(async () => {
		await getCountries("");
	});

	useIonViewDidLeave(async () => {
		if( countryRef && countryRef.current)
		{
			countryRef.current.value = null;
		}
	});

	return (
		<IonItem key="selectCountry">
			<IonSelect 
				ref={countryRef}
			 	interface="action-sheet"
				onIonChange={handleChange}
				placeholder="Select country:"
				>
					{
						countries.map((c) =>
							<IonSelectOption value={c.id} key={c.id}>
								{c.country_name}
							</IonSelectOption>
						)
					}
			</IonSelect>
		</IonItem>
	);
};

export default CountrySelect;
